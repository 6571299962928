<script>



	export let current_item;
    export let barangan;
	export let timeframe;
	export let data;
    export let size;
    export let dslmust;
    export let dbg;

    const _key = 'HargaPurata'
    $: dbg&&console.group('%c'+_key,'color:magenta');


    $: dbg&&console.log('barangan', barangan);



    import * as d3 from 'd3';
    import d3comparator from "$lib/d3comparator.js";

    import moment from 'moment';


    //--------------------------------------------------------------------------------
    // json requests
    //--------------------------------------------------------------------------------
    import { onMount } from "svelte";
    onMount(async () => {
        if (timeframe) {

            let dsl = {};

            if (dslmust && Object.entries(dslmust).length > 0 && dslmust.constructor === Object) {
                dsl = dslmust;
            }else {

                dsl = {
                    // timeframe: "now-7d/d"
                    // timeframe: data.itemMaxWeekDate,

                    timeframe: timeframe,
                    metric: metric,
                }

                if (data.stateid) dsl.stateid = data.stateid;
                if (data.districtid) dsl.districtid = data.districtid;
                if (data.group) dsl.group = data.group;
                if (data.category) dsl.category = data.category;
                if (data.subcategory) dsl.subcategory = data.subcategory;
                if (dbg) dsl.dbg = 1;

            }

            dbg&&console.log('dsl', dsl);


            await d3.json("/req/es/other_items/purata", {
                headers: {"Content-type": "application/json; charset=UTF-8"},
                method: 'POST',
                body:JSON.stringify(dsl)
            })
            .then(res=>{
                dbg&&console.log('res', res);

                barangan = res._items_avg;
                barangan.forEach(d=>{

                        if (data.districtid) {

                            d.url = urlBarangNegeriDaerah(
                                        d.lookup_item.item, d.lookup_item.item_code,
                                        data?._latest?.lookup_premise?.state, data?._latest?.lookup_premise?.stateid,
                                        data?._latest?.lookup_premise?.district, data?._latest?.lookup_premise?.districtid
                                    );

                        }else if (data.stateid) {

                            d.url = urlBarangNegeri(
                                        d.lookup_item.item, d.lookup_item.item_code,
                                        data?._latest?.lookup_premise?.state, data?._latest?.lookup_premise?.stateid
                                    );

                        }else {
                            d.url = urlBarang( d.lookup_item.item, d.lookup_item.item_code )
                        }

                })

            });

        }

    });



    //----------------------------------
    // harga_purata
    //----------------------------------

    $: barangan.forEach(d=>{

                        if (data.districtid) {

                            d.url = urlBarangNegeriDaerah(
                                        d.lookup_item.item, d.lookup_item.item_code,
                                        data?._latest?.lookup_premise?.state, data?._latest?.lookup_premise?.stateid,
                                        data?._latest?.lookup_premise?.district, data?._latest?.lookup_premise?.districtid
                                    );

                        }else if (data.stateid) {

                            d.url = urlBarangNegeri(
                                        d.lookup_item.item, d.lookup_item.item_code,
                                        data?._latest?.lookup_premise?.state, data?._latest?.lookup_premise?.stateid
                                    );

                        }else {
                            d.url = urlBarang( d.lookup_item.item, d.lookup_item.item_code )
                        }

                    });

    // $: dbg&&console.log('barangan url', barangan[0].url);

    //--------------------------------------------------------------------------------
    // current group and category
    //--------------------------------------------------------------------------------

	const metric = 'purata_negara';

    $: current_group = current_item ? current_item.lookup_item?.item_group : null;
    $: current_category = current_item ? current_item.lookup_item?.item_category : null;




    $: items = barangan||[];

    // $: item_group = items && items.length ? items[0]?.lookup_item?.item_group : null;
    // $: item_category = items && items.length ? items[0]?.lookup_item?.item_category : null;

    //--------------------------------------------------------------------------------
    // color scale
    //--------------------------------------------------------------------------------
    $: minMaxPurata = d3.extent( items, d=>d.prev_diff || 0);
    $: min = d3.min([minMaxPurata[0], -1]);
    $: max = d3.max([minMaxPurata[1], 1]);

    // $: dbg&&console.log('min, 0, max', [min, 0, max] );
    // $: _scale = d3.scaleLinear().domain([min, 0, max]).range([1,.5,0]);

    const _color = d3.scaleSequential(d3.interpolateRdYlGn);

    $: _scale1 = d3.scaleLinear().domain([min, 0]).range([1,.6]);
    $: _scale2 = d3.scaleLinear().domain([0, max]).range([.4,0]);


    //--------------------------------------------------------------------------------
    // carian
    //--------------------------------------------------------------------------------

    let keyword;
    $: _keyword = (keyword||'').replace(/\W+/g,' ').toLowerCase();
    $: _carianResult = items.filter(d=>d.lookup_item.item.toLowerCase().match(_keyword));

    $: if (_keyword) current_group = null;

    // $: dbg&&console.log('current_group',current_group);


    //--------------------------------------------------------------------------------
    // default group and category
    //--------------------------------------------------------------------------------

    $: groups           = d3.groups(_carianResult,d=>d.lookup_item.item_group)
                            .sort(d3comparator().order(d3.descending, d=>d[1].length));

    // $: dbg&&console.log('groups',groups);

    // check if current_group is in groups
    $: current_group = groups.length
                        ? groups.find(d=>d[0]==current_group)
                            ? current_group
                            : groups.sort(d3comparator().order(d3.descending, d=>d[1].length))[0][0]
                        : null;

    // $: dbg&&console.log('current_group',current_group);

    $: groupKey         = groups.length
                            ? current_group
                                ? groups.find(d=>d[0]==current_group)?.[0]
                                : groups.sort(d3comparator().order(d3.descending, d=>d[1].length))[0][0]
                            : null;

    // $: dbg&&console.log('groupKey',groupKey);

    $: activeGroup      = groups.length && groupKey ? groups.find(d=>d[0]==groupKey) : null;

    // $: dbg&&console.log('activeGroup',activeGroup);

    $: categories       = activeGroup
                            ? d3.groups(activeGroup[1], d=>d.lookup_item.item_category)
                                .sort(d3comparator().order(d3.descending, d=>d[1].length))
                            : null;

    // $: dbg&&console.log('categories',categories);
    // $: dbg&&console.log('current_category',current_category);

    $: categoryKey      = categories && categories.length
                            ? current_category
                                ? categories.find(d=>d[0]==current_category)
                                    ? categories.find(d=>d[0]==current_category)[0]
                                    : categories[0][0]
                                : categories[0][0]
                            : null


    // $: dbg&&console.log('categoryKey',categoryKey);

    $: activeCategory   = categories && categories.length ? categories.find(d=>d[0]==categoryKey) : null;

    // $: premise_group       = d3.groups( activeCategory && activeCategory[1] ? activeCategory[1] : [], d=>d?.lookup_premise?.premise_code )
	// 							.forEach(d=>{
	// 								d[1].sort(d3comparator().order(d3.ascending, d=>d[metric+'_price_diff'] ))
	// 							})

    // $: premise_group_group = premise_group.filter(d=>d[1].length > 1)
    //                             .sort(d3comparator().order(d3.descending, d=>d[1].length))

    // $: premise_group_singles = d3.merge(premise_group.filter(d=>d[1].length == 1).map(d=>d[1]))
    //                             .sort(d3comparator().order(d3.ascending, d=>d[metric+'_price_diff'] ))

    $: premise_group_singles = (activeCategory && activeCategory[1] ? activeCategory[1] : [])
                                .sort(d3comparator()
                                    .order(d3.ascending, d=>d['prev_diff'] || 0 )
                                    .order(d3.ascending, d=>d['avg'] || 0 )
                                )


    // $: dbg&&console.log('premise_group_group',premise_group_group);
    // $: dbg&&console.log('premise_group_singles',premise_group_singles);

    import {
        urlBarang,
        urlBarangNegeri,
        urlBarangNegeriDaerah,
    } from "$lib/components/Utilities.svelte";

    import { Input } from "$lib/components/ui/input";
    import { Button } from "$lib/components/ui/button";
    import * as Card from "$lib/components/ui/card"
    import { Skeleton } from "$lib/components/ui/skeleton";
    import HargaPurataCard from './HargaPurataCard.svelte';


    // $: dbg&&console.log('groupKey', groupKey);

    $: dbg&&console.groupEnd(_key);

</script>


<!-- {#if !data.category} -->
    <div class="my-2">

            <Card.Header class="my-0 py-0">
                <Card.Title tag="h4" class="tracking-normal text-[#427FED]">Carian nama barang</Card.Title>
            </Card.Header>
            <Card.Content class="mx-1 px-1">


                <div class="flex mx-3 my-2">

                    <Button variant="{!!keyword?'destructive':'secondary'}" on:click={()=>{ keyword=null}}
                        class="
                            rounded-tr-none
                            rounded-br-none
                            border
                        "
                    >
                        {#if !!keyword}
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                        {:else}
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                        {/if}
                    </Button>

                    <Input bind:value={keyword}
                        class="
                            rounded-tl-none
                            rounded-bl-none
                        "
                    />


                </div>


                {#if keyword}
                    <div class="flex justify-start items-stretch flex-wrap gap-1">
                        {#if _carianResult.length}
                                <!-- {#each _carianResult as item}
                                    <HargaPurataCard

                                        {item}
                                        {size}
                                    />
                                {/each} -->
                        {:else}
                            <div class="ml-4 text-slate-700">
                                Tiada nama barangan seperti yang dicari
                            </div>
                        {/if}
                    </div>
                {/if}


            </Card.Content>

    </div>
<!-- {/if} -->


<!-- {#if !(data.group && data.category)} -->

    <div class="flex justify-start items-stretch gap-4 flex-wrap">

        <!-- {#if !data.group} -->

            <div class="flex-1 min-w-[300px]">
                <Card.Header class="my-0 py-0">
                    {#if groups && groups.length}
                        <Card.Title tag="h4" class="tracking-normal text-[#427FED]">Kumpulan Barangan</Card.Title>
                    {:else}
                        <Skeleton class="h-[40px] w-full rounded" />
                    {/if}
                </Card.Header>
                <Card.Content class="mx-1 px-1">
                    <div class="flex justify-start items-stretch flex-wrap my-2 gap-1">
                        {#if groups && groups.length}
                            {#each  groups.sort(d3comparator().order(d3.ascending, d=>d[0])) as d }
                                <Button
                                    class="flex-1 rounded-none border shadow
                                        {d[0]==groupKey? "bg-[#427FED] text-white": 'bg-[#f7f7f7] text-slate-800'}
                                        hover:bg-[purple] hover:text-white"
                                    variant='primary'
                                    on:click={()=>{
                                        current_category = null;
                                        groupKey=d[0];
                                    }}
                                >
                                    {d[0]}
                                    <span class="text-xs m-2 bg-white text-black rounded-full p-1 min-w-6 bg-opacity-70">{d[1].length}</span>
                                </Button>
                            {/each}
                        {:else}
                            <Skeleton class="h-[40px] mx-4 w-full rounded" />
                        {/if}
                    </div>
                </Card.Content>
            </div>
        <!-- {/if} -->

        <!-- {#if !data.category} -->

            <div class="flex-1 min-w-[300px]">
                <Card.Header class="my-0 py-0">
                    {#if groups && groups.length}
                        <Card.Title tag="h4" class="tracking-normal text-[#427FED]">Kategori Barangan</Card.Title>
                    {:else}
                        <Skeleton class="h-[40px] w-full rounded" />
                    {/if}
                </Card.Header>

                <Card.Content class="mx-1 px-1 shadow-sm">
                    <div class="flex justify-start items-stretch flex-wrap my-2 gap-1">
                        {#if categories && categories.length}
                            {#each (categories||[]).sort(d3comparator().order(d3.ascending, d=>d[0])) as d }
                                <Button
                                    class="flex-1 rounded-none border shadow
                                        {d[0]==categoryKey? "bg-[#427FED] text-white": 'bg-[#f7f7f7] text-slate-800'}
                                        hover:bg-[purple] hover:text-white"
                                    variant='primary'
                                    on:click={()=>{
                                        categoryKey=d[0]
                                    }}
                                >
                                    {d[0]}
                                    <span class="text-xs m-2 bg-white text-black rounded-full p-1 min-w-6 bg-opacity-70">{d[1].length}</span>
                                </Button>
                            {/each}
                        {:else}
                            <Skeleton class="h-[40px] mx-4 w-full rounded" />
                        {/if}
                    </div>
                </Card.Content>
            </div>

        <!-- {/if} -->
    </div>
<!-- {/if} -->

<Card.Content class="mx-1 px-1 pb-4">

    <div class="flex justify-center items-stretch flex-wrap gap-1">
        {#if activeCategory && activeCategory[1]}

            <!--
                group by premise
            -->
            <!-- {#each premise_group_group as premise}
                {#each premise[1] as d}
                    <HargaPurataCard
                        {d}
                        {size}
						{metric}
                    />
                {/each}
            {/each} -->

            <!--
                no grouping
            -->
            {#each premise_group_singles.sort(
                d3comparator()
                    .order(d3.ascending, d=>d['prev_diff'] || 0 )
                    .order(d3.ascending, d=>d['avg'] || 0 )
            ) as d}
                <HargaPurataCard
                    {d}
                    {size}
                    {_scale1}
                    {_scale2}
                    {_color}
                />
            {/each}



        {:else}
            <Skeleton class="h-[40px] mx-4 w-full rounded" />
        {/if}
    </div>

</Card.Content>

